<template>
  <div id="RaiseDispute">
    <div class="modal" :class="{ 'is-active': isOpen }">
      <div class="modal-background" @click="close"></div>
      <div class="modal-card">
        <header class="modal-card-head">
          <p class="modal-card-title">{{ t('Manage General Settings') }}
            <span class="icon is-pulled-right" v-if="isLoading">
              <span class="fas fa-spin fa-spinner"></span>
            </span>
          </p>
          <button class="delete" aria-label="close"  v-if="!isLoading" @click="close"></button>
        </header>
        <section class="modal-card-body content mb-0 p-0">
          <table class="custom__table mb-0">
            <thead>
            <tr>
              <th>
                {{ t('Setting') }}
              </th>
              <th>
                {{ t('Value') }}
              </th>
            </tr>
            </thead>
            <tbody v-if="!isLoading">
            <tr v-for="(value, setting) in settingsData" :key="setting">
              <td>
                {{ t(setting) }}
              </td>
              <td>
                <div class="field">
                <div class="control">
                <input type="email" class="input is-small" style="width: 100%;" v-model="settingsData[setting]" >
                </div>
                </div>
              </td>
            </tr>
            </tbody>
          </table>
          <hr class="mt-0" />
        </section>
        <footer class="modal-card-foot p-0">
          <span
              class="card-footer-item has-text-danger is-clickable"
              :disabled="isLoading"
              @click="close"
          >
            {{ t('Cancel') }}
          </span>
          <span
              class="card-footer-item has-text-info is-clickable"
              :disabled="isLoading"
              @click="send"
          >
            {{ t('Confirm') }}
          </span>
        </footer>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.custom__table {
  tbody {
    tr {
      td {
        vertical-align: middle;
        input {
          width: auto;
        }
      }
    }
  }
}
</style>

<script>
export default {
  name: 'GeneralSettingsModal',
  data () {
    return {
      isOpen: false,
      isLoading: false,
      owner_identifier: null,
      parent_identifier: null,

      settingsData: {},

      t: this.$store.getters["Translations/getTranslations"](
          this.$options.name
      ),
    }
  },
  computed: {
  },
  methods: {
    open(values) {
      console.log(values);

      this.owner_identifier = values.owner_identifier;
      this.parent_identifier = values.parent_identifier;
      this.get();

      this.isOpen = true;
    },
    close() {
      this.isOpen = false;
    },
    async send() {
      const {
        owner_identifier,
        parent_identifier,
        settingsData
      } = this;
      try {
        let data = await this.$store.dispatch("organizationalKit/putCompanySettings", {
          company: owner_identifier,
          owner_company: parent_identifier,
          settings: settingsData
        });

        if (!data.success) {
          throw data;
        }

        this.close();
      } catch (error) {
        console.log(error);
      }
    },
    async get() {
      const {
        owner_identifier,
        parent_identifier
      } = this;

      this.isLoading = true;
      try {
        let data = await this.$store.dispatch("organizationalKit/getCompanySettings", {
          company: owner_identifier,
          owner_company: owner_identifier === parent_identifier ? null : parent_identifier,
          settings: [
            'logo',
            'iban',
            'kvk',
            'btw',
            'vat',
            'primary.email',
            'secondary.email',
            'primary.phone',
            'secondary.phone',
            'buyer.id',
            'policy.id',
            'bsn.id',
            'cc.id',
            'passport.id',
            'credit.limit',
            'payment.terms',
            'currency',
          ].concat( ['jm@konnectkit.nl', 'maurice@consultinvest.nl'].indexOf(this.$store.state.me.user.email) === -1 ? [] : [
            'previous.reminder.identifier',
            'previous.reminder.date',
            'subscription.model',
            'subscription.model.cost',
            'subscription.volumetric.pdf.cost',
            'subscription.volumetric.pdf.printing.cost',
            'customer.of.identifier',
          ] )
        });

        if (!data.success) {
          throw data;
        }
        this.settingsData = data.settings || [];

        this.isLoading = false;
      } catch (error) {
        console.log(error);
        this.isLoading = false;
      }
    },
  },
};
</script>
